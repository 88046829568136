function LoggedOut() {
    console.log("LOGOUT COMPLETE");

    try {
        // eslint-disable-next-line no-undef
        Office.onReady(() => {
            // Clear local storage and cookies
            // localStorage.clear();
            // document.cookie.split(";").forEach((c) => {
            //     document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            // });
            // eslint-disable-next-line no-undef
            Office.context.ui.messageParent(JSON.stringify({ }), { targetOrigin: "*" });
        });
    } catch (ex) {
        console.log(ex);
    }

    return <div>LoggedOut</div>;
}

export default LoggedOut;
