import { useAuth0 } from "@auth0/auth0-react";
import LoggedOut from './LoggedOut.js';
import { useEffect, useState } from 'react';

function LogOutWrapper() {
    const { isLoading, error, logout, isAuthenticated } = useAuth0();
    const [isLoggedOut, setIsLoggedOut] = useState(false);

    useEffect(() => {
        const handleLogout = async () => {
            try {
                await logout({ returnTo: window.location.origin });
                // Wait for the Office context to be ready before proceeding
                // eslint-disable-next-line no-undef
                await Office.onReady();
                //setIsLoggedOut(true);
            } catch (logoutError) {
                console.error('Logout failed:', logoutError);
            }
        };

        if (!isLoading && !isLoggedOut) {
            if (isAuthenticated) {
                handleLogout();
            } else {
                setIsLoggedOut(true); // Directly set to logged out if not authenticated
            }
        }
    }, [isLoading, isAuthenticated, isLoggedOut, logout]);

    if (error) {
        return <div>ERROR: {error.message}</div>;
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isLoggedOut) {
        return <LoggedOut />;
    }

    return null;
}

export default LogOutWrapper;
